<template>
  <div class="info-block_color">
    <b-row>
      <b-col
        v-for="column in initFields"
        :key="column.title"
        sm="6"
        cols="12"
        :class="$style.numbersWrapper"
        class="dflex"
      >
        <router-link :to="column.address" :class="$style.title">
          <h5>
            {{ column.title }}
          </h5>
        </router-link>
        <div>
          <ul v-if="checkNumbers(column)" :class="$style.numbers">
            <li v-for="(item, index) in column.data.ServiceItems" :key="index">
              {{ editNumberLook(item.PhoneNumberFormatted) }}
            </li>
          </ul>
          <ul v-else-if="checkLines(column)" :class="$style.numbers">
            <li v-for="(item, index) in column.data.ServiceItems" :key="index">
              {{ item.Lines.join(', ') }}
            </li>
          </ul>
          <info-block>
            <template #text>
              <router-link
                :to="{
                  path: '/create-statement',
                  query: { st: 'addService', service: 'телефонная-линия' }
                }"
                :class="$style.title"
              >
                {{ column.textLink }}
              </router-link>
              <br />
              {{ column.text }}
            </template>
          </info-block>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { employMaskPhone } from '@/additional_helpers/helpers';
import InfoBlock from '@/components/shared/InfoBlock.vue';

export default {
  props: {
    userNumbers: {
      type: Object,
      require: true
    },
    userLines: {
      type: Object,
      require: true
    }
  },

  data() {
    return {
      initFields: [
        {
          title: 'Ваши номера',
          type: 'numbers',
          address: '/my-numbers',
          data: this.userNumbers,
          error: this.isOfficeError,
          textLink: 'Подключите многоканальные номера ONLINE LOGIC,',
          text: 'чтобы клиенты, всегда, могли до вас дозвониться'
        },
        {
          title: 'Ваши Линии',
          type: 'lines',
          address: '/my-lines',
          data: this.userLines,
          error: this.isVpbxError,
          textLink: 'Подключите линии ONLINE LOGIC,',
          text: 'чтобы общение внутри сети стало удобным'
        }
      ],
      errorMessage: 'Услуга "Виртуальный офис" не подключена'
    };
  },

  components: {
    InfoBlock
  },

  computed: {
    // Проверяет есть ли записанные в телефонии номера линий
    isLinesNumbers() {
      const lines = this.userLines.ServiceItems;
      const isNumber = lines.map((el) => el.Lines.length > 0);
      return isNumber.includes(true);
    },

    isOfficeError() {
      return !this.userNumbers.Error;
    },

    isVpbxError() {
      return !this.userLines.Error && this.isLinesNumbers;
    }
  },

  methods: {
    editNumberLook(number) {
      return employMaskPhone(number);
    },

    checkNumbers(column) {
      return !column.error && column.type === 'numbers';
    },

    checkLines(column) {
      return (
        !column.error
        && column.type !== 'numbers'
        && column.data.ServiceItems.some((el) => el.Lines.length > 0)
      );
    }
  }
};
</script>

<style lang="scss" module>
.title {
  color: $hoverBlack;
  padding-right: 1rem;
  text-decoration: underline;

  &:hover {
    list-style-type: none;
    color: $hoverBlack;
  }
}

.numbersWrapper {
  flex-direction: column;
  text-align: center;
}

.numbers {
  padding-left: 0;

  & > li {
    line-height: 2;
    font-weight: 400;
  }
}

.numbersWrapper:last-child {
  padding-top: 3rem;
}

.lineWrap {
  justify-content: center;
}

@media (min-width: 576px) {
  .numbersWrapper:last-child {
    padding-top: 0;
  }
}

@media (min-width: 992px) {
  .numbersWrapper {
    text-align: left;
  }

  .numbers {
    padding-left: revert;

    & > li {
      list-style-type: disc;
    }
  }
}
</style>
