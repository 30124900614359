import URL from '@/basUrl';
import { setPostOptionsWithCookie } from '@/api/common';

const options = {
  method: 'GET',
  credentials: 'include'
};

export const getNumbers = async () => {
  const response = await fetch(URL.VIRTUAL_PBX, options);
  // if (response.status === 404) return response.status;
  return response.json();
};

export const getLines = async () => {
  const response = await fetch(URL.VIRTUAL_PBX, options);
  // if (response.status === 404) return response.status;
  return response.json();
};

export const sendCustomerNewNumbers = async (raw) => {
  const response = await fetch(URL.RESERVE_NUMBERS, setPostOptionsWithCookie(raw));
  return response;
};
