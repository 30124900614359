<template>
  <base-table>
    <template #headerText>
      Опции
    </template>
    <template #additional>
      <b-row>
        <b-col v-for="item in userLines.Options" :key="item.Id" xl="6">
          <b-row :class="$style.option" class="dflex align-between">
            <b-col cols="9" :class="$style.title">
              <span>{{ item.Name }}</span>
            </b-col>
            <b-col cols="3">
              <router-link
                :to="{
                  name: 'OptionRequest',
                  query: { id: item.Id },
                }"
                :class="[
                  $style.fakeCheckbox,
                  item.IsEnabled ? $style.fakeCheckboxActive : $style.fakeCheckboxDisactive,
                ]"
              ></router-link>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </template>
  </base-table>
</template>

<script>
import BaseTable from '@/components/shared/BaseTable.vue';

export default {
  props: {
    userLines: {
      type: Object,
      require: true
    }
  },

  components: {
    BaseTable
  }
};
</script>

<style lang="scss" module>
@import '@/assets/scss/_vpbx-options';
</style>
