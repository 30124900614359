<template>
  <aside class="d-lg-flex align-center valign-center d-none" :class="$style.batteryWrap">
    <h4 :class="$style.title">Заряди свой бизнес!</h4>
    <div :class="$style.battery">
      <svg viewBox="0 5 60 140" height="100%">
        <rect x="5" y="10" fill="#D10000" width="50" height="130"></rect>
        <rect x="10" y="15" fill="#FFFFFF" width="40" height="120"></rect>
        <rect x="20" y="5" fill="#000000" width="20" height="5"></rect>
        <line
          id="line3"
          x1="30"
          y1="130"
          x2="30"
          y2="20"
          stroke="lightgray"
          stroke-dasharray="2 3"
          stroke-width="30"
        ></line>
        <line
          id="line4"
          x1="30"
          y1="130"
          x2="30"
          :y2="y2"
          stroke="black"
          stroke-dasharray="2 3"
          stroke-width="30"
        ></line>
      </svg>
    </div>
    <div :class="$style.batteryText">Подключено {{ percent }}% опций</div>
  </aside>
</template>

<script>
import { getServiceData } from '@/api/common';
import { catchFunction } from '@/additional_helpers/helpers';
import URL from '@/basUrl';

export default {
  data() {
    return {
      percent: 0,
      y2: 130
    };
  },

  created() {
    getServiceData(URL.BUTTERY)
      .then((result) => {
        this.percent = result;
        this.colorizeBattery();
      })
      .catch((e) => catchFunction(e));
  },

  methods: {
    colorizeBattery() {
      if (this.percent > 0 && this.percent < 100) {
        this.y2 = Math.floor(128 - 5 * (this.percent / 4.5454) + 3);
      } else if (this.percent === 100) {
        this.y2 = 23;
      } else {
        this.y2 = 130;
      }
    }
  }
};
</script>

<style lang="scss" module>
.title {
  color: $red;
}

.batteryWrap {
  padding-top: 2rem;
  position: relative;
  flex-direction: column;
  text-align: center;
}

.batteryText {
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
}

.battery {
  max-width: 7rem;
  svg {
    width: 100%;
  }
}
</style>
