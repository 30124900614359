<template>
  <the-wrapper :isAside="isAside" :isSpinner="isSpinner">
    <slot>
      <vpbx-info :userNumbers="userNumbers" :userLines="userLines"></vpbx-info>
      <br />
      <options-list v-if="isOptions" :userLines="userLines"></options-list>
    </slot>
    <template #aside>
      <the-battery></the-battery>
    </template>
  </the-wrapper>
</template>

<script>
import VpbxInfo from '@/components/Vpbx/VpbxInfo.vue';
import OptionsList from '@/components/Vpbx/options/OptionsList.vue';
import TheBattery from '@/components/TheBattery.vue';
import TheWrapper from '@/components/shared/TheWrapper.vue';
import { getNumbers, getLines } from '@/api/vpbx';
import { catchFunction } from '@/additional_helpers/helpers';
import { mapMutations } from 'vuex';

export default {
  data() {
    return {
      userNumbers: {},
      userLines: {},
      isAside: true,
      isSpinner: true
    };
  },

  components: {
    VpbxInfo,
    OptionsList,
    TheBattery,
    TheWrapper
  },

  created() {
    Promise.all([getNumbers(), getLines()])
      .then((result) => {
        const [numbers, lines] = result;
        this.userNumbers = numbers;
        this.userLines = lines;
      })
      .then(() => this.SHOW_LOADING(false))
      .catch((e) => catchFunction(e));
  },

  computed: {
    isOptions() {
      if (this.userLines.ServiceItems) return !this.userLines.Error;
      return false;
    }
  },

  methods: mapMutations({
    SHOW_LOADING: 'SHOW_LOADING'
  })
};
</script>
